import { Block } from 'baseui/block';
import { Link } from 'shared/ui';
import { styled } from 'styletron-react';

export const Header = styled(Block, {
  height: '113px', // Set fixed height for the header
  marginBottom: 'scale800', // Separation from the body
  backgroundColor: 'rgb(66, 168, 209)', // Background color
  color: 'white', // White font color
  padding: '16px', // Padding for a traditional header feel
  textAlign: 'left', // Left-aligned text
  fontSize: '2rem', // Adjust font size to resemble h1
  fontWeight: 'bold',
  display: 'flex', // Use flexbox to align items
  alignItems: 'center', // Vertically center the text
});

export const Wrapper = styled('div', {
  width: '90%', // Limit width to 80%
  maxWidth: '1200px', // Optional: Set a maximum width for larger screens
  margin: '0 auto', // Center the component
  padding: '16px', // Optional: Add some padding
  boxSizing: 'border-box', // Include padding in the total width
});

export const ClickableLink = styled(Link, {
  cursor: 'pointer', // Change cursor to pointer for better UX
  textDecoration: 'none', // Remove underline
  color: 'white', // Inherit color from the parent header
  textDecorationLine: 'none',
});
