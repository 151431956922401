import { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { PermissionRender, PermissionsGate } from 'shared/lib';
import RequireAuth from './auth/require-auth';
import SessionRoom from './session-room';
// import TherapistListing from './provider-search/TherapistListing';
import TherapistPage from './provider-search/TherapistPage';
// import TherapistSearch from './provider-search/TherapistSearch';

const Auth = lazy(() => import('./auth/router'));
const AppLayout = lazy(() => import('widgets/app-layout'));
const Participants = lazy(() => import('./participants/router'));
const Providers = lazy(() => import('./providers/router'));
const Practitioners = lazy(() => import('./practitioners/router'));
const Booking = lazy(() => import('./booking/router'));
const Services = lazy(() => import('./services/router'));
const Administration = lazy(() => import('./administration/router'));
const Appointments = lazy(() => import('./appointments/router'));
const PublicBooking = lazy(() => import('./public-booking/router'));
const PublicTherapistSearch = lazy(() => import('./provider-search/router'));

const ParticipantDashboard = lazy(
  () => import('./participant-dashboard/router')
);
const AccountSettings = lazy(() => import('./account-settings/router'));
const ChatRedirect = lazy(() => import('./chats/redirect'));
const Reports = lazy(() => import('./reports/router'));
const Payments = lazy(() => import('./payments/router'));
const Documents = lazy(() => import('./documents/router'));
const PatientSettings = lazy(() => import('./patient-settings/router'));
const Dispatcher = lazy(() => import('./dispatcher/router'));
const ErrorPage = lazy(() => import('./error'));
const UserLayout = lazy(() => import('widgets/user-layout'));
const Contacts = lazy(() => import('./contacts/router'));
const ProviderOnboarding = lazy(() => import('./provider-onboarding/router'));
const ParticipantChats = lazy(() => import('./participant-chats/router'));
const Chats = lazy(() => import('./chats/router'));

const adminInterfaceRoles = [
  'Admin',
  'Account Administrator',
  'External Admin',
  'External Administrator',
  'Practitioner',
  'Scheduler',
  'Dispatcher',
  'Clinical Supervisor',
];

export function AppRouter() {
  return (
    <Routes>
      <Route path="/provider-page/*" element={<TherapistPage />} />
      <Route path="/provider-search/*" element={<PublicTherapistSearch />} />
      <Route path="/auth/*" element={<Auth />} />
      <Route element={<RequireAuth />}>
        <Route
          element={
            <PermissionsGate
              anyRolesOf={adminInterfaceRoles}
              redirectLink="/participant-dashboard"
            />
          }
        >
          <Route element={<AppLayout />}>
            <Route index element={<Navigate to="/appointments" replace />} />
            <Route path="/appointments/*" element={<Appointments />} />
            <Route path="/chat/*" element={<ChatRedirect />} />
            <Route path="/chats/*" element={<Chats />} />
            <Route path="/participants/*" element={<Participants />} />
            <Route path="/providers/*" element={<Providers />} />
            <Route path="/practitioners/*" element={<Practitioners />} />
            <Route path="/dispatcher/*" element={<Dispatcher />} />
            <Route path="/services/*" element={<Services />} />
            <Route path="/booking/*" element={<Booking />} />
            <Route path="/administration/*" element={<Administration />} />
            <Route path="/reports/*" element={<Reports />} />
            <Route path="/payments/*" element={<Payments />} />
          </Route>
          <Route path="/account-settings/*" element={<AccountSettings />} />
        </Route>
        <Route element={<PermissionsGate anyRolesOf={[]} />}>
          <Route path="/meeting-room/*" element={<SessionRoom />} />
        </Route>
      </Route>
      <Route element={<RequireAuth />}>
        <Route element={<PermissionsGate roles={['Patient']} />}>
          <Route
            path="/participant-dashboard/*"
            element={<ParticipantDashboard />}
          />
          <Route path="/documents/*" element={<Documents />} />
          <Route path="/account-details/*" element={<PatientSettings />} />

          <Route path="/participant-chats/*" element={<ParticipantChats />} />
        </Route>
      </Route>
      <Route path="/book/*" element={<PublicBooking />} />
      <Route path="/book-:location/*" element={<PublicBooking />} />
      <Route path="/contacts/*" element={<Contacts />} />
      <Route path="/provider-onboarding/*" element={<ProviderOnboarding />} />

      <Route
        path="/404"
        element={
          <RequireAuth>
            <PermissionRender
              anyRolesOf={adminInterfaceRoles}
              fallbackRender={
                <UserLayout>
                  <ErrorPage />
                </UserLayout>
              }
            >
              <AppLayout>
                <ErrorPage />
              </AppLayout>
            </PermissionRender>
          </RequireAuth>
        }
      />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  );
}
