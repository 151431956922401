/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable effector/no-getState */

import { FC, useEffect } from 'react';
import { useStore } from 'effector-react';
import { FlexGrid, FlexGridItem } from 'baseui/flex-grid';
import { Block } from 'baseui/block';
import { FiUser, FiUsers, FiMonitor, FiMapPin } from 'react-icons/fi';
import { Desktop, Mobile } from 'shared/ui';
import { useTherapistFilterLogic } from '../../hooks/use-therapist-filter-logic';
import { $filters, $therapists } from '../../hooks/therapist-filter.store';
import { getTherapistsEvent } from '../../hooks/use-get-therapists';
import FilterButton from '../buttons/filter/filter-buttons';
import { TherapistFilter } from '../filter-modal';
import { TherapistCard } from '../therapist-card/TherapistCard';
import { ClickableLink, Header, Wrapper } from '../shared/styles';
import { STATES_OPTIONS } from '../filter-modal/constants';

const TherapistListing: FC = () => {
  useEffect(() => {
    console.log('rendering TherapistListing and calling getTherapistEvent()');
    getTherapistsEvent();
  }, []);

  const therapists = useStore($therapists);
  const { handleGenderButtonClick } = useTherapistFilterLogic();
  const filters = useStore($filters);

  const stateNames = STATES_OPTIONS.filter((option) =>
    filters.licensed_states?.includes(option.id)
  )
    .map((option) => option.label)
    .join(', '); // Combine the state names into a comma-separated string

  return (
    <>
      <Header>
        <ClickableLink href="/provider-search">
          <Desktop>TrustVideo - Provider Network</Desktop>
          <Mobile>
            TrustVideo
            <div style={{ marginTop: '20px' }}>
              <Block>Provider Network</Block>
            </div>
          </Mobile>
        </ClickableLink>
      </Header>
      <Block padding="scale800">
        <Wrapper>
          <Block
            marginBottom="scale800"
            overrides={{
              Block: {
                style: {
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  color: '#374151',
                },
              },
            }}
          >
            Therapists in {stateNames}
            {/* <h4>filters: {filters.gender}</h4> */}
            {/* <h4>FemaleIsSelected={filters.gender?.includes('Female')}</h4> */}
            {/* <h4>MaleIsSelected={filters.gender?.includes('Male')}</h4> */}
          </Block>

          <Block
            display="flex"
            marginBottom="scale800"
            overrides={{
              Block: {
                style: ({ $theme }) => ({
                  flexDirection: 'row', // Ensure row layout for large screens
                  width: '100%',
                  alignItems: 'center', // Align items vertically
                  [$theme.mediaQuery.small]: {
                    flexDirection: 'column', // Stack on small screens
                    width: '100%',
                  },
                  [$theme.mediaQuery.medium]: {
                    flexDirection: 'column',
                    width: '100%',
                  },
                  [$theme.mediaQuery.large]: {
                    flexDirection: 'row',
                    width: '100%',
                  },
                }),
              },
            }}
          >
            {/* Filter Buttons - 50% width */}
            <Block
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    display: 'flex',
                    flexDirection: 'row', // Ensure row layout for large screens
                    width: '100%',
                    marginBottom: '8px',
                    alignItems: 'center', // Align items vertically
                    [$theme.mediaQuery.small]: {
                      flexDirection: 'row', // Stack on small screens
                      justifyContent: 'space-between',
                      width: '100%',
                    },
                    [$theme.mediaQuery.medium]: {
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                      width: '100%',
                    },
                    [$theme.mediaQuery.large]: {
                      flexDirection: 'row',
                      width: '100%',
                      justifyContent: 'flex-start',
                      gap: '8px',
                    },
                  }),
                },
              }}
            >
              {/* <FilterButtons /> */}
              {/* <span>{selectedGender}</span> */}
              <FilterButton
                label="Female"
                icon={FiUser}
                onFilterChange={() => handleGenderButtonClick('Female')}
                isSelected={filters.gender?.includes('Female')}
              />
              <FilterButton
                label="Male"
                icon={FiUsers}
                onFilterChange={() => handleGenderButtonClick('Male')}
                isSelected={filters.gender?.includes('Male')}
              />
              {/* <FilterButton label="In Person" icon={FiMapPin} />
              <FilterButton label="Online" icon={FiMonitor} /> */}
            </Block>

            {/* Buttons Block - 50% width */}
            <Block
              display="flex"
              // justifyContent="space-between"
              // width="100%" // Ensures the remaining 50% is divided between the two buttons
              overrides={{
                Block: {
                  style: ({ $theme }) => ({
                    [$theme.mediaQuery.small]: {
                      width: '100%',
                      flexDirection: 'column',
                    },
                    [$theme.mediaQuery.medium]: {
                      width: '100%',
                      flexDirection: 'column',
                    },
                    [$theme.mediaQuery.large]: {
                      width: '50%', // Ensure buttons share remaining 50%
                      flexDirection: 'row',
                    },
                  }),
                },
              }}
            >
              {/* Filter Button - 25% of the row */}
              <Block
                overrides={{
                  Block: {
                    style: ({ $theme }) => ({
                      flexBasis: '100%', // Takes the remaining 25% of the full row
                      [$theme.mediaQuery.small]: {
                        flexBasis: '100%',
                      },
                      [$theme.mediaQuery.medium]: {
                        flexBasis: '100%',
                      },
                      [$theme.mediaQuery.large]: {
                        flexBasis: '100%',
                        marginBottom: '8px',
                      },
                    }),
                  },
                }}
              >
                <TherapistFilter />
              </Block>
            </Block>
          </Block>

          <FlexGrid flexGridColumnCount={1} flexGridRowGap="scale800">
            {therapists.length === 0 ? (
              <FlexGridItem>
                <h1>No Therapists Available</h1>
              </FlexGridItem>
            ) : (
              therapists.map((therapist) => (
                <FlexGridItem key={therapist.id}>
                  <TherapistCard therapist={therapist} />
                </FlexGridItem>
              ))
            )}
          </FlexGrid>
        </Wrapper>
      </Block>
    </>
  );
};

export default TherapistListing;
