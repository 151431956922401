/* eslint-disable @typescript-eslint/no-unused-vars */

import { FC, useState } from 'react';
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE,
} from 'baseui/modal';
import { Button } from 'baseui/button';
import { Checkbox } from 'shared/ui/checkbox';
import { Tag, KIND } from 'baseui/tag';
import { Select } from 'shared/ui';
import { GENDERS, SERVICE, STATES_OPTIONS } from './constants';
import { useTherapistFilterLogic } from '../../hooks/use-therapist-filter-logic';

const TherapistFilter: FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedState, setSelectedState] = useState<string[]>([]);

  const {
    localFilters,
    clearAllLocalFilters,
    handleGenderTagClick,
    handleServiceClick,
    handleLicensedStatesClick,
    handleSubmit,
  } = useTherapistFilterLogic();

  return (
    <>
      <Button
        onClick={() => setIsOpen(true)}
        overrides={{
          Root: {
            style: { borderRadius: '30px', width: '100%', height: '100%' },
          },
        }}
      >
        Open Filter
      </Button>

      <Modal
        onClose={() => setIsOpen(false)}
        closeable
        isOpen={isOpen}
        animate
        size={SIZE.auto}
        role={ROLE.dialog}
        overrides={{
          Dialog: {
            style: {
              width: '500px',
              height: '80vh',
              display: 'flex',
              flexDirection: 'column',
            },
          },
        }}
      >
        <ModalHeader>Select a Filter</ModalHeader>

        <ModalBody style={{ flex: 1, overflow: 'auto' }}>
          <div>
            <div style={{ marginTop: '20px' }}>
              <h3>Popular Filters...</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap', gap: '8px' }}>
                {SERVICE.filter(
                  (filter) =>
                    filter === 'Behavioral Therapy' ||
                    filter === 'Crisis Intervention' ||
                    filter === 'Routine Check-ups' ||
                    filter === 'Speech Therapy' ||
                    filter === ''
                )
                  // .sort(() => Math.random() - 0.5) // too much noise on re-render
                  .map((filter) => (
                    <Tag
                      key={filter}
                      onClick={() => handleServiceClick(filter)}
                      kind={
                        localFilters.service?.includes(filter)
                          ? KIND.accent
                          : KIND.primary
                      }
                      closeable={false}
                    >
                      {filter}
                    </Tag>
                  ))}
              </div>
            </div>

            <div style={{ marginTop: '20px' }}>
              <h3>Show only states...</h3>
              <Select
                placeholder="AVAILABLE STATES"
                options={STATES_OPTIONS}
                multi
                value={STATES_OPTIONS.filter((option) =>
                  selectedState.includes(option.id)
                )}
                onChange={({ value }) => {
                  const newSelectedIds = (value as { id: string }[])
                    .map((selectedOption) => selectedOption.id)
                    .filter((id): id is string => !!id);
                  setSelectedState(newSelectedIds);
                  handleLicensedStatesClick(newSelectedIds);
                }}
              />
            </div>

            <div style={{ marginTop: '20px' }}>
              <h3>Show only therapists who are...</h3>
              {GENDERS.map((filter) => (
                <Tag
                  key={filter}
                  onClick={() => handleGenderTagClick(filter)}
                  kind={
                    localFilters.gender?.includes(filter)
                      ? KIND.accent
                      : KIND.primary
                  }
                  closeable={false}
                >
                  {filter}
                </Tag>
              ))}
            </div>

            <div style={{ marginTop: '20px' }}>
              <h3>Show only services...</h3>
              {SERVICE.map((service) => (
                <Checkbox
                  key={service}
                  checked={localFilters.service?.includes(service)}
                  onChange={() => handleServiceClick(service)}
                >
                  {service}
                </Checkbox>
              ))}
            </div>

            {/* <div style={{ marginTop: '20px' }}>
            <h3>How would you like to see your therapist?</h3>
            {APPOINTMENT_TYPES.map((type) => (
              <Tag
                key={type}
                onClick={() => handleAppointmentTypeClick(type)}
                kind={
                  selectedAppointmentType === type ? KIND.accent : KIND.primary
                }
                closeable={false}
              >
                {type}
              </Tag>
            ))}
          </div>
          */}
          </div>
        </ModalBody>

        <ModalFooter>
          <ModalButton kind="tertiary" onClick={clearAllLocalFilters}>
            Clear All Filters
          </ModalButton>
          <ModalButton
            onClick={() => {
              handleSubmit();
              setIsOpen(false);
            }}
          >
            Show Therapists
          </ModalButton>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TherapistFilter;
